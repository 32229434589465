// Generated by Framer (25dafa9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["eAaQ17nU8", "CVeGW4h4n"];

const variantClassNames = {CVeGW4h4n: "framer-v-1i7kaoy", eAaQ17nU8: "framer-v-u724h0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Close: "CVeGW4h4n", Hamburger: "eAaQ17nU8"};

const transitions = {CVeGW4h4n: {damping: 20, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 300, type: "spring"}, default: {damping: 20, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 300, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; color?: string; radius?: number; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "eAaQ17nU8", color: w9_afj2vz = "rgb(0, 0, 0)", radius: d2WdKj7yR = 1, tap: lWUdvXxiL, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "eAaQ17nU8", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap188iy09 = activeVariantCallback(async (...args) => {
if (lWUdvXxiL) {
const res = await lWUdvXxiL(...args);
if (res === false) return false;
}
setVariant("CVeGW4h4n")
})

const onTap104izwr = activeVariantCallback(async (...args) => {
if (lWUdvXxiL) {
const res = await lWUdvXxiL(...args);
if (res === false) return false;
}
setVariant("eAaQ17nU8")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-cmi5m", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-u724h0", className)} data-framer-name={"Hamburger"} data-highlight layoutDependency={layoutDependency} layoutId={"eAaQ17nU8"} onTap={onTap188iy09} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({CVeGW4h4n: {"data-framer-name": "Close", onTap: onTap104izwr}}, baseVariant, gestureVariant)}><motion.div className={"framer-8tmg27"} layoutDependency={layoutDependency} layoutId={"JJDr1vyxv"} style={{backgroundColor: w9_afj2vz, borderBottomLeftRadius: d2WdKj7yR, borderBottomRightRadius: d2WdKj7yR, borderTopLeftRadius: d2WdKj7yR, borderTopRightRadius: d2WdKj7yR, rotate: 0}} transition={transition} variants={{CVeGW4h4n: {rotate: 45}}}/><motion.div className={"framer-1blgejv"} layoutDependency={layoutDependency} layoutId={"O6qWAvyTt"} style={{backgroundColor: w9_afj2vz, borderBottomLeftRadius: d2WdKj7yR, borderBottomRightRadius: d2WdKj7yR, borderTopLeftRadius: d2WdKj7yR, borderTopRightRadius: d2WdKj7yR, rotate: 0}} transition={transition} variants={{CVeGW4h4n: {rotate: -45}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-cmi5m [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cmi5m .framer-smznen { display: block; }", ".framer-cmi5m .framer-u724h0 { cursor: pointer; height: 30px; overflow: hidden; position: relative; width: 30px; }", ".framer-cmi5m .framer-8tmg27 { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: 10px; width: 24px; }", ".framer-cmi5m .framer-1blgejv { bottom: 10px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; width: 24px; }", ".framer-cmi5m.framer-v-1i7kaoy .framer-8tmg27 { top: calc(50.00000000000002% - 2px / 2); }", ".framer-cmi5m.framer-v-1i7kaoy .framer-1blgejv { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CVeGW4h4n":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"w9_afj2vz":"color","d2WdKj7yR":"radius","lWUdvXxiL":"tap"}
 */
const FramerVwpMB1x46: React.ComponentType<Props> = withCSS(Component, css, "framer-cmi5m") as typeof Component;
export default FramerVwpMB1x46;

FramerVwpMB1x46.displayName = "Hamburger 2";

FramerVwpMB1x46.defaultProps = {height: 30, width: 30};

addPropertyControls(FramerVwpMB1x46, {variant: {options: ["eAaQ17nU8", "CVeGW4h4n"], optionTitles: ["Hamburger", "Close"], title: "Variant", type: ControlType.Enum}, w9_afj2vz: {defaultValue: "rgb(0, 0, 0)", title: "Color", type: ControlType.Color}, d2WdKj7yR: {defaultValue: 1, displayStepper: true, max: 1, min: 0, title: "Radius", type: ControlType.Number}, lWUdvXxiL: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerVwpMB1x46, [])